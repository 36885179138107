class DeliveryCalculator {
  constructor(block) {
    this.block = block
    this.postcodeSearchBtn = block.querySelector(".delivery-calculator__block-input-btn")
    this.deliveryInfoElement = block.querySelector(".delivery-calculator__block-info")
    this.deliveryInfoMessage = block.querySelector(".delivery-calculator__block-info-text")
    this.postcodeInput = block.querySelector(".delivery-calculator__block-input-field")
    this.loadingSpinner = block.querySelector(".delivery-calculator__block-loading")
    // Regular expression for UK Postcodes format validation
    this.postcodeRegex = /^[A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s*\d[A-Za-z]{2}$/
    this.events()
  }

  events() {
    this.postcodeSearchBtn.addEventListener("click", () => {
      this.calculateDelivery()
    })
  }

  // Method to calculate delivery information based on a UK postcode
  async calculateDelivery() {
    // Gets the Postcode input by EU (End-User)
    let postcode = this.postcodeInput.value.trim()

    if (!this.isValidPostcode(postcode)) return

    // Extract outcode from the postcode
    let match = postcode.match(/^([A-Za-z]{1,2}\d{1,2}[A-Za-z]?)\s?(\d[A-Za-z]{2})$/)
    if (match) {
      let outcode = match[1]
      let incode = match[2]
      postcode = `${outcode} ${incode}`
    }

    // Reset the styling every time the SEARCH button is clicked
    this.resetStyle()

    // Show loading spinner while data is being retrieved
    this.showLoadingSpinner()

    try {
      let deliveryInfo = await this.getDeliveryInfo(postcode)
      this.displayDeliveryInfo(deliveryInfo)
    } catch (error) {
      this.displayErrorMessage(error.message)
    } finally {
      // Hide the loading spinner after data retrieval
      this.hideLoadingSpinner()
    }
  }

  // Shows the loading spinner
  showLoadingSpinner() {
    this.loadingSpinner.style.display = "flex"
  }

  // Hides the loading spinner
  hideLoadingSpinner() {
    this.loadingSpinner.style.display = "none"
  }

  isValidPostcode(postcode) {
    // Check if the Postcode input is empty, and if so display warning to the EU, or if it matches the regex
    if (!postcode) {
      this.displayErrorMessage("Postcode field cannot be empty!", this.postcodeInput)
      return false
    } else if (!this.postcodeRegex.test(postcode)) {
      this.displayErrorMessage("Please enter a valid UK postcode!", this.postcodeInput)
      return false
    } else {
      return true
    }
  }

  // Sets and styles the error message and displays to the EU
  displayErrorMessage(message, inputElement) {
    this.deliveryInfoElement.style.display = "flex"
    this.deliveryInfoMessage.innerHTML = message

    if (inputElement) {
      inputElement.style.borderColor = "red"
    }

    this.deliveryInfoMessage.style.color = "red"
  }

  disableCheckoutButton() {
    const checkoutButton = document.querySelector(".checkout-button")
    if (checkoutButton) {
      console.log("Disabling checkout button")
      checkoutButton.classList.add("disabled-checkout-button")
    }
  }

  enableCheckoutButton() {
    const checkoutButton = document.querySelector(".checkout-button")
    if (checkoutButton) {
      console.log("Enabling checkout button")
      checkoutButton.classList.remove("disabled-checkout-button")
    }
  }

  // Display appropriate response to the EU
  displayDeliveryInfo(deliveryInfo) {
    this.deliveryInfoElement.style.display = "flex"

    if (deliveryInfo && deliveryInfo.postcode) {
      this.enableCheckoutButton()
      this.deliveryInfoMessage.innerHTML = `Delivery to the postcode <strong>${deliveryInfo.postcode.toUpperCase()}</strong> will incur a <strong>£${deliveryInfo.cost_inc_vat}</strong> delivery charge (including VAT).`
    } else {
      this.disableCheckoutButton()
      this.deliveryInfoMessage.innerHTML = "<strong>Unfortunately we cannot deliver to this postcode at this time. For more information please call our office on 01420 521040.</strong>"
    }
  }

  // Reset styling
  resetStyle() {
    this.postcodeInput.style.borderColor = ""
    this.deliveryInfoMessage.style.color = ""
    this.deliveryInfoElement.style.display = "none"
  }

  // Method to fetch delivery information from server
  async getDeliveryInfo(postcode) {
    try {
      let formData = new FormData()

      formData.append("postcode", postcode)
      formData.append("action", "get_delivery_cost")

      let response = await fetch("/wp-admin/admin-ajax.php", {
        method: "POST",
        body: formData,
      })

      let shippingData = await response.json()

      console.log(shippingData)

      if (response.ok) {
        return shippingData
      } else {
        throw new Error(shippingData.error || "There was an unexpected error whilst trying to fetch the delivery info!")
      }
    } catch (error) {
      console.error("Error fetching delivery info:", error)
      throw error
    }
  }
}

export default DeliveryCalculator
