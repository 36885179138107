import "bootstrap"
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

import Header from "./components/Header.js"
import Hero from "./components/Hero"
import SingleProduct from "./components/SingleProduct.js"
import ArchiveProduct from "./components/ArchiveProduct.js"
import DeliveryCalculator from "./components/DeliveryCalculator"
import {initializeCarousel} from "./components/Flickities"
import Accordion from "./components/Accordion.js"

for (const header of document.querySelectorAll(".site-header")) {
  new Header(header)
}

for (const block of document.querySelectorAll(".hero")) {
  new Hero(block)
}

for (const page of document.querySelectorAll(".wc-single-product")) {
  new SingleProduct(page)
}

for (const page of document.querySelectorAll(".wc-archive-product")) {
  new ArchiveProduct(page)
}

for (const block of document.querySelectorAll(".delivery-calculator__block")) {
  new DeliveryCalculator(block)
}

for (const accordionItem of document.querySelectorAll(".faqs__accordion-item")) {
  new Accordion(accordionItem)
}

//initialise flickity once dom ready
document.addEventListener("DOMContentLoaded", () => {
  //product single carousel
  initializeCarousel(".wc-single-product__gallery", {
    cellAlign: "center",
    contain: true,
    //wrapAround: true,
    autoPlay: 4000,
    pageDots: false,
    prevNextButtons: false,
  })
})

//Reinit delivery calculator on cart update
$(document.body).on("updated_wc_div", (e) => {
  for (const block of document.querySelectorAll(".delivery-calculator__block")) {
    new DeliveryCalculator(block)
  }
})
